import { Button, Dialog, DialogContent } from "@mui/material"
import useLogoutModal from "./useLogoutModal"
import errorScreen from "../../assets/images/error_screen.svg"

import styles from "./index.module.css"

const LogoutModal = () => {
    const {
        states: { open, countdown },
        functions: { onClose, signInHandler },
    } = useLogoutModal()

    return (
        <Dialog open={open} onClose={onClose} maxWidth={"xs"} aria-labelledby="form-dialog-title">
            <DialogContent className="regular_modal_font" style={{ margin: "20px 60px" }}>
                <div className="flex_col align_center gap_15">
                    <img height={"120px"} src={errorScreen} alt="logout modal" />
                    <div>
                        <p>Your Session Has Expired.</p>
                        <p className="fs_12 text_center">
                            Logging out in {countdown} {countdown === 1 ? "second" : "seconds"}.
                        </p>
                    </div>

                    <Button className={styles.signinBtn} variant="contained" color="primary" onClick={signInHandler}>
                        Sign In
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default LogoutModal
