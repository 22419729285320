import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getDecryptedItem } from "../helpers"
import { updateUserInfo, setRefreshTokenExpired } from "../modules/landing/authSlice"
import { setAlertFunc } from "../components/Toaster/toasterSlice"

const BaseURLS = [
    {
        url: process.env.REACT_APP_BASE_URL,
        extraHeaders: false,
        header: {},
    },
    {
        url: process.env.REACT_APP_TRIGGER_URL,
        extraHeaders: true,
        header: [
            {
                header_key: "CSMS_TOKEN_VALUE",
                header_value: "",
            },
            {
                header_key: "source",
                header_value: "csms",
            },
        ],
    },
]

let currentBaseObj = {}
const customBaseUrl = path => {
    const userInfo = getDecryptedItem("userInfo")

    currentBaseObj = BaseURLS[0]
    if (window.location.pathname.includes("auto-trigger")) {
        currentBaseObj = BaseURLS[1]
        currentBaseObj.header[0].header_value = userInfo.token
    }
    return currentBaseObj
}

const baseQuery = fetchBaseQuery({
    baseUrl: "",
    prepareHeaders: (headers, { getState }) => {
        const userInfo = getDecryptedItem("userInfo")
        const token = userInfo?.token
        const currCountryId = userInfo?.country_id ?? getDecryptedItem("countryId")
        if (currCountryId) headers.set("country-id", currCountryId)
        if (token) headers.set("x-stq-apiKey", `${token}`)
        if (currentBaseObj?.extraHeaders)
            currentBaseObj?.header?.map(item => headers.set(item.header_key, item.header_value))

        return headers
    },
})

let isRefreshing = false
let refreshPromise = null

const baseQueryWithReauth = async (args, api, extraOptions) => {
    // function to set the current base obj
    customBaseUrl(args.url)
    // Ensure we pass the full URL by prepending the baseUrl to args.url
    const fullUrl = `${currentBaseObj?.url}${args.url}`
    let result = await baseQuery({ ...args, url: fullUrl }, api, extraOptions)
    if (result.error && result.error.status === 401) {
        if (result.error?.data?.message === "Token is invalid!") {
            if (!isRefreshing) {
                // If not already refreshing, start the refresh process
                isRefreshing = true
                refreshPromise = refreshToken(api, extraOptions)
            }

            // Wait for the refreshPromise to resolve before retrying the initial query
            try {
                const refreshResult = await refreshPromise
                // Retry the initial query if refresh is successful
                if (refreshResult?.data) {
                   
                    result = await baseQuery(args, api, extraOptions)
                } else {
                    // Logout when refresh token fails
                    api.dispatch(setRefreshTokenExpired(true))
                }
            } catch (error) {
                console.error("Failed to refresh token.", error)
            } finally {
                isRefreshing = false
                refreshPromise = null
            }
        } else {
            api.dispatch(
                setAlertFunc({
                    type: "error",
                    message:
                        result.error?.data?.message || result.error?.data?.meta?.message || result.error?.data?.msg,
                })
            )
        }
    }

    return result
}

const refreshToken = async (api, extraOptions) => {
    try {
        // Make API call to refresh the token
        const userData = getDecryptedItem("userInfo")
        const refreshToken = userData?.refresh_token
        const userId = userData?.id

        const refreshResult = await baseQuery(
            { url: "signin/generate_new_tokens", params: { id: userId }, headers: { "Refresh-Token": refreshToken } },
            api,
            extraOptions
        )

        if (refreshResult.data) {
            // Update access token in userInfo
            const newUserInfo = { ...userData }
            newUserInfo.token = refreshResult.data.token.access_token
            api.dispatch(updateUserInfo({ userInfo: newUserInfo }))
        }
        return refreshResult
    } catch (error) {
        console.error("Token refresh failed:", error)
        return error
    }
}

export const mainSplitApi = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
    tagTypes: ["State", "City", "token", "registered", "metric", "details", "auth", "mapped", "admin", "autoTriggerTable"],
})
